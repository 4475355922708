/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface IMetaItem {
  name: string;
  content: string;
}

interface ISEOProps {
  title?: string;
  description?: string;
  keywords?: string[];
  meta?: IMetaItem[];
  lang?: string;
}

const Head: React.FC<ISEOProps> = props => {
  const { site, InventoryQuery } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            lang
          }
        }
      }
    `
  );

  const {
    title = props.title,
    description = props.description,
    meta = props.meta || [],
    lang = props.lang || "en"
  } = site.siteMetadata;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${title}`}
      meta={[
        {
          name: `description`,
          content: description
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: description
        }
      ].concat(meta)}
    />
  );
};

export default Head;
